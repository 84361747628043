import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2025 Pinko de Mayo Release Party',
	date: '2025-04-10',
	image: require('./2025-pinko@2x.png'),
	body: (
		<>
			<p>
				PINKO! This time in the VINES at{' '}
				<a href="https://square.link/u/tU7tKehb" target="_blank">
					Ox-Eye Vineyards in Staunton, VA
				</a>
				. Saturday, May 3 from 1-4PM.
			</p>
			<p>
				It’s our annual Spring Thing but this time we’re doing it a little differently as we introduce you to some 
				of our friends who help keep this show on the road.
			</p>
			<p>
				This year we’re doing PINKO at the gorgeous Ox-Eye Vineyards in Staunton, VA (not the tasting room, but out 
				on the farm!)
			</p>
			<p>
				Spend time with us and the Kiers family walking the vines where our Riesling comes from, learning how we go 
				from grape to juice, and MOST importantly, drinking the all-new releases for spring 2025!
			</p>
			<p>
				This will be the most excellent day. We’re excited to be able to show you how Ox-Eye makes the magic happen 
				for us — all of us! AND DON’T FORGET TO WEAR PINK, YA RASCALS!
			</p>
			<p>
				<strong>TICKETS</strong>
				<br />
				{' '}
				<a href="https://square.link/u/tU7tKehb">
					$30 or $40 at-the-door
				</a>
				<br />
				Members of THE CIRCUS (our wine club) get in free! Check your email for a code.
			</p>
			<p>
				<strong>WHEN</strong>
				<br />
				Saturday, May 3th from 1-4PM
			</p>
			<p>
				<strong>WHERE</strong>
				<br />
				Ox-Eye Vineyard
				<br />
				847 Miller Farm Road
				<br />
				Staunton VA 24401
				<br />
				<a href="https://maps.app.goo.gl/RWqtXixx798ARJDr5" target="_blank">
					Directions
				</a>
				<br />
			</p>
			<p>
				<strong>WHAT</strong>
				<br />A funky pink party celebrating the joys of spring and juice!
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong>
				<br />
				Hit us up on{' '}
				<a href="https://www.instagram.com/lightwellsurvey/" target="_blank">
					IG
				</a>{' '}
				or <a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
		</>
	),
	cta: <Button.Link href="https://square.link/u/tU7tKehb" target="_blank">Buy Tickets Now!</Button.Link>
}
